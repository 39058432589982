<template>
  <div>
    <div class="space-query">
      <el-input size="small" v-model="queryField.orderId" placeholder="订单id"></el-input>
      <el-input size="small" v-model="queryField.cpOrderId" placeholder="cp订单id"></el-input>
      <el-input size="small" v-model="queryField.userId" placeholder="用户id"></el-input>
      <el-input size="small" v-model="queryField.orgId" placeholder="家族id"></el-input>
      <el-autocomplete
          size="small"
          v-model.trim="game"
          :fetch-suggestions="querySearch"
          placeholder="游戏名称"
          @select="handleSelect"
      ></el-autocomplete>
      <el-select size="small" v-model="queryField.orderType">
        <el-option label="全部" value=""></el-option>
        <el-option label="游戏订单" value="1"></el-option>
        <el-option label="星元订单" value="2"></el-option>
        <el-option label="余额订单" value="3"></el-option>
      </el-select>
      <el-select size="small" v-model="queryField.orderStatus">
        <el-option label="全部" value=""></el-option>
        <el-option label="待支付" value="1"></el-option>
        <el-option label="支付成功" value="2"></el-option>
        <el-option label="通知成功" value="3"></el-option>
        <el-option label="退款" value="4"></el-option>
      </el-select>
      <el-select size="small" v-model="queryField.payType">
        <el-option label="全部" value=""></el-option>
        <el-option label="平台币" value="1"></el-option>
        <el-option label="支付宝" value="2"></el-option>
        <el-option label="微信" value="3"></el-option>
        <el-option label="支付宝APP" value="4"></el-option>
        <el-option label="微信APP" value="5"></el-option>
      </el-select>
      <el-date-picker
          size="small"
          v-model="dateValue"
          type="datetimerange"
          align="right"
          unlink-panels
          value-format="yyyy-MM-dd HH:mm:ss"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00','23:59:59']"
          :picker-options="pickerOptions"
          @change="onDataConfirm">
      </el-date-picker>
      <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
      <el-button size="small" @click="reset">重置</el-button>
    </div>
    <div class="price-view">
      <el-row :gutter="30">
        <el-col :span="3">
          总金额(￥)：{{ allAmount }}
        </el-col>
        <el-col :span="3">
          成功金额(￥)：{{ successAmount }}
        </el-col>
        <el-col :span="3">
          平台币(￥)：{{ ptbAmount }}
        </el-col>
        <el-col :span="3">
          微信(￥)：{{ wxAmount }}
        </el-col>
        <el-col :span="3">
          支付宝(￥)：{{ zfbAmount }}
        </el-col>
      </el-row>
    </div>
    <el-table
        class="space-table"
        :data="tableData"
        :height="height"
        border
        style="width: 100%">
      <el-table-column
          prop="order_id"
          label="订单id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="cp_order_id"
          label="cp订单id"
          width="200">
      </el-table-column>
      <el-table-column
          prop="user_id"
          label="用户id"
          width="80">
      </el-table-column>
      <el-table-column
          prop="nickname"
          label="渠道名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="game_name"
          label="游戏名称"
          width="120">
      </el-table-column>
      <el-table-column
          prop="order_role_name"
          label="下单角色"
          width="120">
      </el-table-column>
      <el-table-column
          prop="order_service_name"
          label="下单区服"
          width="120">
      </el-table-column>
      <el-table-column
          prop="order_desc"
          label="订单描述"
          width="120">
      </el-table-column>
      <el-table-column
          prop="order_amount"
          label="订单金额"
          width="80">
      </el-table-column>
      <el-table-column
          prop="discount_amount"
          label="优惠金额"
          width="80">
      </el-table-column>
      <el-table-column
          prop="order_time"
          label="下单时间"
          width="155">
      </el-table-column>
      <el-table-column
          prop="order_type"
          label="订单类型"
          :formatter="formatterOrderType"
          width="100">
      </el-table-column>
      <el-table-column
          prop="order_status"
          label="订单状态"
          :formatter="formatterOrderStatus"
          width="100">
      </el-table-column>
      <el-table-column
          prop="pay_type"
          label="支付方式"
          :formatter="formatterPayType"
          width="100">
      </el-table-column>
      <el-table-column
          prop="org_name"
          label="家族名称"
          width="120">
      </el-table-column>
      <el-table-column
        label="操作">
        <template v-slot="scope">
          <el-button type="text" @click="toReinform(scope.row.order_id)">重新通知</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
        class="space-table-data"
        background
        layout="total, prev, pager, next"
        :page-size="pageSize"
        hide-on-single-page
        @current-change="loadData"
        :current-page="currentPage"
        :total="total">
    </el-pagination>
  </div>
</template>

<script>
import {getGame, queryOrder, queryOrderCount, reinform} from "@/api/request";

export default {
  name: "OrderList",
  data(){
    return {
      queryField: {
        orderId: '',
        cpOrderId: '',
        userId: '',
        gameId: '',
        orgId: '',
        orderType: '1',
        payType: '',
        orderStatus: '3',
        startTime: '',
        endTime: ''
      },
      game: '',
      restaurants: [],
      dateValue: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      tableData: [],
      pageSize: 13,
      currentPage: 1,
      total: 0,
      height: window.innerHeight - 250,
      allAmount: 0,
      successAmount: 0,
      ptbAmount: 0,
      wxAmount: 0,
      zfbAmount: 0
    }
  },
  mounted() {
    this.setDate();
    this.loadGame();
    this.loadData(1);
  },
  methods: {
    setDate(){
      let newdata = new Date();
      if ((newdata.getMonth() + 1) < 1) {
        var y = newdata.getFullYear() - 1;
        var m = newdata.getMonth() + 12;
      } else {
        var y = newdata.getFullYear();
        var m = newdata.getMonth() + 1;
      }
      m = m < 10 ? '0' + m : m;
      var d = newdata.getDate() - 1;
      d = d < 10 ? ('0' + d) : d;
      d = d === 31 ? 30 : d;
      this.queryField.startTime = y + '-' + m + '-' + d + " 00:00:00";
      this.queryField.endTime = y + '-' + m + '-' + newdata.getDate() + ' 23:59:59';
      this.dateValue = [this.queryField.startTime,this.queryField.endTime];
    },
    loadData(index){
      let params = {
        pageNo: index,
        pageSize: this.pageSize,
        params: this.queryField
      }
      queryOrder(params).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.currentPage = index;
      })
      this.loadOrderCount();
    },
    loadOrderCount(){
      queryOrderCount(this.queryField).then(res => {
        if(res.data.success){
          let _data = res.data.data;
          this.allAmount = _data.allAmount;
          this.successAmount = _data.successAmount;
          this.ptbAmount = _data.ptbAmount;
          this.wxAmount = _data.wxAmount;
          this.zfbAmount = _data.zfbAmount;
        }
      })
    },
    reset(){
      this.queryField = {
        orderId: '',
        cpOrderId: '',
        userId: '',
        gameId: '',
        orderType: '1',
        payType: '',
        orderStatus: '3',
        startTime: '',
        endTime: ''
      }
      this.game = ''
      this.setDate();
      this.loadData(1);
    },
    loadGame() {
      getGame().then(res => {
        this.restaurants = [];
        res.data.data.forEach(e => {
          let item = {};
          item.value = e.gameName;
          item.gameId = e.gameId;
          this.restaurants.push(item);
        })
      })
    },
    toReinform(orderId){
      reinform(orderId).then(res => {
        if(res.data.success){
          this.$message.success("重新通知成功");
        }else {
          this.$message.error(res.data.error.message);
        }
      })
    },
    querySearch(queryString, callback){
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createStateFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      callback(results);
    },
    createStateFilter(queryString) {
      return (state) => {
        return (state.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item){
      this.queryField.gameId = item.gameId;
    },
    onDataConfirm(){
      if(this.dateValue !== null){
        this.queryField.startTime = this.dateValue[0];
        this.queryField.endTime = this.dateValue[1];
      }else {
        this.queryField.startTime = "";
        this.queryField.endTime = "";
      }
    },
    formatterOrderType(row, column, cellValue){
      let rep = "";
      if(cellValue === 1){
        rep = "游戏订单";
      }else if(cellValue === 2){
        rep = "星元订单";
      }else if(cellValue === 3){
        rep = "余额订单";
      }
      return rep;
    },
    formatterOrderStatus(row, column, cellValue){
      let rep = "";
      if(cellValue === 1){
        rep = "待支付";
      }else if(cellValue === 2){
        rep = "支付成功";
      }else if(cellValue === 3){
        rep = "通知成功";
      }else if(cellValue === 4){
        rep = "退款";
      }
      return rep;
    },
    formatterPayType(row, column, cellValue){
      let rep = '';
      if(cellValue === 11){
        rep = '平台币';
      }else if(cellValue === 12){
        rep = '代金券';
      }else if(cellValue === 21){
        rep = '微信H5';
      }else if(cellValue === 22){
        rep = '微信扫码';
      }else if(cellValue === 23){
        rep = '微信app';
      }else if(cellValue === 31){
        rep = '支付宝H5';
      }else if(cellValue === 32){
        rep = "支付宝扫码";
      }else if(cellValue === 33){
        rep = "支付宝app";
      }else if(cellValue === 91){
        rep = "联运";
      }
      return rep;
    }
  }
}
</script>

<style scoped>
.price-view {
  width: 99%;
  height: 50px;
  line-height: 50px;
  margin: 10px 0;
  padding:0 10px;
  background-color: #f6f6f6;
  border-radius: 3px;
  
  font-size: 14px;
}
</style>