import axios from "@/api/axios";
import AWS from "aws-sdk";
const ACL = "public-read";

/**************************登录登出****************************/
export async function login(params = {}){
    return axios({
        url: `/admin/login/doLogin`,
        method: "post",
        data: params
    })
}

export async function logout(){
    return axios({
        url: `/admin/login/logout`,
        method: "post"
    })
}

/****************************菜单*******************************/
export async function getMenu(){
    return axios({
        url: `/menu/list`,
        method: "post"
    })
}

/****************************游戏管理*******************************/
export async function queryGame(params = {}){
    return axios({
        url: `/game/list`,
        method: "post",
        data: params
    })
}

export async function addGame(params = {}){
    return axios({
        url: `/game/add`,
        method: "post",
        data: params
    })
}

export async function queryGameByGameId(gameId){
    return axios({
        url: `/game/one/${gameId}`,
        method: "post"
    })
}

export async function queryGameTopic(){
    return axios({
        url: `/game/topic/get`,
        method: 'post'
    })
}

export async function getGame(){
    return axios({
        url: `game/getGameName`,
        method: 'post',
    })
}

export async function updateGame(params = {}){
    return axios({
        url: `/game/edit`,
        method: "post",
        data: params
    })
}

export async function saveLogo(filename,gameId){
    return axios({
        url: `/game/saveLogo/${filename}/${gameId}`,
        method: 'post',
    })
}

export function uploadLogo(item){
    return new Promise((resolve, reject) => {
        let ossData = {};
        getOssData(item.data.gameId).then(res => {
            if(res.data.success){
                ossData = res.data.data;
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                });

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)

                let upload = new AWS.S3.ManagedUpload({
                    partSize: 500 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: "image/" + ossData.name + "/logo" + suffix,
                        Body: item.file,
                        ACL: "public-read",
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                upload.on("httpUploadProgress",e => {
                    let loaded = e.loaded;//已经上传大小情况
                    let total = e.total;//附件总大小
                    let per = Math.floor(100 * loaded / total);  //已经上传的百分比
                    item.data.progress = per;
                });
                upload.send((err, data) => {
                    if(err){
                        console.log(err)
                    }else {
                        resolve(res.data);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

export function uploadPackage(item){
    return new Promise((resolve, reject) => {
        let ossData = {};
        getOssData(item.data.gameId).then(res => {
            if(res.data.success){
                ossData = res.data.data;
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                });

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)

                let upload = new AWS.S3.ManagedUpload({
                    partSize: 100 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: ossData.name + "/" + ossData.shortName + suffix,
                        Body: item.file,
                        ACL: "public-read",
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                upload.on("httpUploadProgress",e => {
                    let loaded = e.loaded;//已经上传大小情况
                    let total = e.total;//附件总大小
                    let per = Math.floor(100 * loaded / total);  //已经上传的百分比
                    item.data.progress = per;
                });
                upload.send((err, data) => {
                    if(err){
                        console.log(err);
                    }else {
                        resolve(res.data);
                        saveGameSize(item.data.gameId, item.file.size);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

export async function refreshCDN(gameId){
    return axios({
        url: `/game/refresh?gameId=${gameId}`,
        method: 'get'
    })
}

export async function packWd(gameId){
    return axios({
        url: `/game/pack/${gameId}`,
        method: "post"
    })
}

export async function oneKeySubpackage(gameId){
    return axios({
        url: `/game/oneKeySubpackage?gameId=${gameId}`,
        method: 'get',
    })
}

export async function saveGameSize(gameId,fileSize){
    return axios({
        url: `/game/saveGameSize?gameId=${gameId}&fileSize=${fileSize}`,
        method: "get"
    })
}

export async function queryTask(params = {}){
    return axios({
        url: `/game/task/taskList`,
        method: 'post',
        data: params
    })
}

export async function addGameTask(params = {}){
    return axios({
        url: `/game/task/add`,
        method: 'post',
        data: params
    })
}

export async function saveGameTaskVideo(taskId){
    return axios({
        url: `/game/task/saveVideo?id=${taskId}`,
        method: 'get'
    })
}

export function uploadGameTaskVideo(item){
    return new Promise((resolve, reject) => {
        let ossData = {};
        getOssData(item.data.gameId).then(res => {
            if(res.data.success){
                ossData = res.data.data;
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                });

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)

                let upload = new AWS.S3.ManagedUpload({
                    partSize: 500 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: "task/" + item.data.taskId + "/task" + suffix,
                        Body: item.file,
                        ACL: "public-read",
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                upload.on("httpUploadProgress",e => {
                    let loaded = e.loaded;//已经上传大小情况
                    let total = e.total;//附件总大小
                    let per = Math.floor(100 * loaded / total);  //已经上传的百分比
                    item.data.progress = per;
                });
                upload.send((err, data) => {
                    if(err){
                        console.log(err)
                    }else {
                        resolve(res.data);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

export async function queryGameConfig(params = {}){
    return axios({
        url: '/game/config/configList',
        method: "post",
        data: params
    })
}

export async function saveGameConfig(params = {}){
    return axios({
        url: `/game/config/saveConfig`,
        method: "post",
        data: params
    })
}

export function uploadGameVideo(item){
    return new Promise((resolve, reject) => {
        let ossData = {};
        getOssData(item.data.gameId).then(res => {
            if(res.data.success){
                ossData = res.data.data;
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                });

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)
                
                let path = "gameVideo/" + ossData.name + "/" + Date.now() + suffix;
                let upload = new AWS.S3.ManagedUpload({
                    partSize: 500 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: path,
                        Body: item.file,
                        ACL: "public-read",
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                upload.on("httpUploadProgress",e => {
                    let loaded = e.loaded;//已经上传大小情况
                    let total = e.total;//附件总大小
                    let per = Math.floor(100 * loaded / total);  //已经上传的百分比
                    item.data.progress = per;
                });
                upload.send((err, data) => {
                    if(err){
                        console.log(err)
                    }else {
                        res.data.path = path;
                        resolve(res.data);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

export async function addGameVideo(params = {}){
    return axios({
        url: `/game/video/addGameVideo`,
        method: "post",
        data: params
    })
}

export async function queryGameVideo(params = {}){
    return axios({
        url: `/game/video/gameVideoList`,
        method: "post",
        data: params
    })
}

export async function getGameSearch(params = {}){
    return axios({
        url: `/game/search/getGameSearch`,
        method: "post",
        data: params
    })
}

export async function addGameSearch(params = {}) {
    return axios({
        url: `/game/search/addGameSearch`,
        method: 'post',
        data: params
    })
}

export async function saveGameSearch(params = {}){
    return axios({
        url: `/game/search/saveGameSearch`,
        method: 'post',
        data: params
    })
}

/********************************resource*******************************/

export async function queryGameResourceType(){
    return axios({
        url: `/game/resource/getGameResourceTypeList`,
        method: "get"
    })
}

export async function addGameResource(params = {}){
    return axios({
        url: `/game/resource/addGameResource`,
        method: "post",
        data: params
    })
}

export async function getGameResourceList(params = {}){
    return axios({
        url: `/game/resource/getGameResourceList`,
        method: "post",
        data: params
    })
}

export async function deleteGameResource(resourceId) {
    return axios({
      url: '/game/resource/deleteGameResource/'+resourceId,
      method: 'get'
    })
  }


/********************************oss*******************************/
export async function getOssData(gameId){
    return axios({
        url: `/oss/config/${gameId}`,
        method: "post",
    })
}

export async function getCtyunOSSToken(){
    return axios({
        url: `/oss/ctyunConfig`,
        method: "post",
    })
}

/********************************系统管理*******************************/
export async function queryAdmin(param = {}){
    return axios({
        url: `/admin/list`,
        method: 'post',
        data: param
    })
}

export async function addAdmin(param = {}){
    return axios({
        url: `admin/add`,
        method: 'post',
        data: param
    })
}

export async function queryNotice(param = {}){
    return axios({
        url: `/notice/noticeList`,
        method: "post",
        data: param
    })
}

export async function addNotice(params= {}){
    return axios({
        url: `/notice/add`,
        method: "post",
        data: params
    })
}

export async function updateNoticeStatus(id){
    return axios({
        url: `/notice/invalid?id=${id}`,
        method: 'get',
    })
}

export async function changePassword(params = {}){
    return axios({
        url: `/admin/changePassword`,
        method: 'post',
        data: params
    })
}

export async function getDrawRecord(params = {}) {
    return axios({
        url: `/award/provide/getProvide`,
        method: 'post',
        data: params
    })
}

export async function updatePass(id) {
    return axios({
        url: `/award/provide/updatePass?id=${id}`,
        method: 'get',
    })
}

export async function updateRepeal(id) {
    return axios({
        url: `/award/provide/updateRepeal?id=${id}`,
        method: 'post',
    })
}

export async function getAwardGameList() {
    return axios({
        url: `/award/provide/getAwardGameList`,
        method: 'post',
    })
}

/*********************************商品管理***********************************/
export async function queryShopGoods(params = {}){
    return axios({
        url: `/shop/goods/goodsList`,
        method: 'post',
        data: params
    })
}

export async function addGoods(params = {}){
    return axios({
        url: `/shop/goods/addGoods`,
        method: "post",
        data: params
    })
}

export async function doSoldOut(goodsId){
    return axios({
        url: `/shop/goods/soldOut/${goodsId}`,
        method: "post",
    })
}

export async function queryShopGoodsOrder(params = {}){
    return axios({
        url: `/shop/order/shopOrder`,
        method: 'post',
        data: params
    })
}

export async function saveGoodsImg(params = {}){
    return axios({
        url: `/shop/goods/saveGoodsImg`,
        method: 'post',
        data: params
    })
}

export function uploadGoodsImg(item){
    return new Promise((resolve, reject) => {
        let ossData = {};
        getOssData(item.data.gameId).then(res => {
            if(res.data.success){
                ossData = res.data.data;
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                });

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)
                let path = "goods/" + ossData.name + "/" + ossData.shortName + suffix;
                let upload = new AWS.S3.ManagedUpload({
                    partSize: 500 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: path,
                        Body: item.file,
                        ACL: "public-read",
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                upload.send((err, data) => {
                    if(err){
                        console.log(err)
                    }else {
                        res.data.data.path = path;
                        resolve(res.data);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

/********************************用户管理**********************************/
export async function queryUser(params = {}){
    return axios({
        url: `/user/userList`,
        method: 'post',
        data: params
    })
}

export async function queryUserAccount(params = {}){
    return axios({
        url: `/user/account/accountList`,
        method: 'post',
        data: params
    })
}


/**********************************家族***********************************/
export async function queryOrg(params = {}){
    return axios({
        url: `/org/orgList`,
        method: "post",
        data: params
    })
}

export async function getOrgConfig(params = {}){
    return axios({
        url: `/org/config/list`,
        method: 'post',
        data: params
    })
}

export async function updateOrgShare(params = {}){
    return axios({
        url: `/org/config/saveShare`,
        method: "post",
        data: params
    })
}

export async function getOrg(){
    return axios({
        url: `/org/orgName`,
        method: "post",
    })
}

/*******************************upload*********************************/
/**
 *  上传文件
 * @param item 参数
 * @param isProgress 进度条进度
 * @param callback 文件存储路径
 */
export function upload(item,isProgress,callback){
    return new Promise((resolve, reject) => {
        let ossData = {};
        getOssData(item.data.gameId).then(res => {
            if(res.data.success){
                ossData = res.data.data;
                let gameData = {name: ossData.name,shortName: ossData.shortName}
                let c = callback(gameData);
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                });

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)
                let path = c + suffix;
                let upload = new AWS.S3.ManagedUpload({
                    partSize: 100 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: path,
                        Body: item.file,
                        ACL: "public-read",
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                if(isProgress){
                    upload.on("httpUploadProgress",e => {
                        let loaded = e.loaded;//已经上传大小情况
                        let total = e.total;//附件总大小
                        let per = Math.floor(100 * loaded / total);  //已经上传的百分比
                        item.data.progress = per;
                    });
                }
                upload.send((err, data) => {
                    if(err){
                        console.log(err)
                    }else {
                        res.data.path = path;
                        resolve(res.data);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

export function uploadContentTextImg(item){
    return new Promise((resolve,reject) => {
        let ossData = {};
        getCtyunOSSToken().then(res => {
            if(res.data.success){
                ossData = res.data.data;
                AWS.config.update({
                    accessKeyId: ossData.accessId,
                    secretAccessKey: ossData.accessKey,
                    sessionToken: ossData.sessionToken,
                    signatureVersion: "v2",
                    endpoint: ossData.endpoint,
                })

                // 上传文件名称
                let filename = item.file.name
                let pos = filename.lastIndexOf('.')
                let suffix = ''
                //获取尾缀
                if (pos !== -1) suffix = filename.substring(pos)

                let key = "content/" + new Date().getTime() + suffix;
                let upload = new AWS.S3.ManagedUpload({
                    partSize: 500 * 1024 * 1024, //分片大小
                    params: {
                        Bucket: ossData.bucket, //桶
                        Key: key,
                        Body: item.file,
                        ACL: ACL,
                        ContentType: item.file.type,
                        'Access-Control-Allow-Credentials': '*',
                    },
                });
                upload.send((err, data) => {
                    if(err){
                        console.log(err)
                    }else {
                        res.data.key = key;
                        resolve(res.data);
                    }
                })
            }else {
                reject(res.data);
            }
        })
    })
}

/************************************order*****************************************/
export async function queryOrder(params = {}){
    return axios({
        url: `/order/list`,
        method: 'post',
        data: params
    })
}

export async function reinform(orderId){
    return axios({
        url: `/order/reinform/${orderId}`,
        method: 'post',
    })
}

export async function queryOrderCount(params = {}){
    return axios({
        url: `/order/getCount`,
        method: 'post',
        data: params
    })
}

/*************************************礼包管理********************************************/
export async function queryGiftList(params = {}){
    return axios({
        url: `/gift/getGiftList`,
        method: "post",
        data: params
    })
}

export async function addGift(params = {}){
    return axios({
        url: `/gift/addGift`,
        method: "post",
        data: params
    })
}

export async function getGift(giftId = ''){
    return axios({
        url: `/gift/getGift?giftId=${giftId}`,
        method: 'get'
    })
}

export async function editGift(params = {}) {
    return axios({
        url: `/gift/editGift`,
        method: 'post',
        data: params
    })
}

export async function getGiftCode(params = {}){
    return axios({
        url: `/gift/code/getGiftCode`,
        method: 'post',
        data: params
    })
}

export async function getGiftRecord(params = {}){
    return axios({
        url: `/gift/codeRecord/getGiftCodeRecord`,
        method: 'post',
        data: params
    })
}

export async function addGiftCode(params = {}){
    return axios({
        url: `/gift/code/addGiftCode`,
        method: 'post',
        data: params
    })
}

/**************************************攻略**********************************/
export async function queryContent(params = {}){
    return axios({
        url: `/content/getContent`,
        method: 'post',
        data: params
    })
}

export async function getContent(contentId = ""){
    return axios({
        url: `/content/getContentDetail?contentId=${contentId}`,
        method: 'get',
    })
}

export async function saveContent(params = {}){
    return axios({
        url: `/content/saveContent`,
        method: 'post',
        data: params
    })
}

export async function addContent(params = {}){
    return axios({
        url: `/content/addContent`,
        method: 'post',
        data: params
    })
}