<template>
    <div>
        <div class="space-query">
            <el-row @keyup.enter.native="loadData(1)">
                <el-input v-model="queryField.userId" size="small" placeholder="用户id"/>
                <el-input v-model="queryField.awardId" size="small" placeholder="奖品id"/>
                <el-select v-model="queryField.gameId" size="small" placeholder="游戏名称">
                    <el-option v-for="item in gameOptions" :label="item.game_name" :value="item.game_id"></el-option>
                </el-select>
                <el-select v-model="queryField.provide" size="small" placeholder="发放状态">
                    <el-option label="未发放" value="0"/>
                    <el-option label="已发放" value="1"/>
                    <el-option label="已撤销" value="2"/>
                </el-select>
                <el-date-picker
                    v-model="dateValue"
                    size="small"
                    type="datetimerange"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00','23:59:59']"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    style="width: 400px"
                    @change="onDataConfirm">
                </el-date-picker>
                <el-button type="primary" size="small" @click="loadData(1)">查询</el-button>
                <el-button size="small" @click="reset">重置</el-button>
            </el-row>
        </div>
        <el-table
            class="space-table"
            :data="tableData"
            :height="height"
            border
            style="width: 100%">
            <el-table-column
                prop="userId"
                label="用户id"
                width="100">
            </el-table-column>
            <el-table-column
                prop="awardId"
                label="奖品id"
                width="100">
            </el-table-column>
            <el-table-column
                prop="awardName"
                label="奖品名"
                width="150">
            </el-table-column>
            <el-table-column
                prop="gameName"
                label="游戏名称"
                width="150">
            </el-table-column>
            <el-table-column
                prop="thirdUserName"
                label="微信昵称"
                width="120">
            </el-table-column>
            <el-table-column
                prop="infoCode"
                label="唯一标志"
                width="200">
            </el-table-column>
            <el-table-column
                prop="gainTime"
                label="中奖时间"
                width="150">
            </el-table-column>
            <el-table-column
                prop="provide"
                label="奖品状态"
                width="100">
                <template slot-scope="scope">
                    <el-tag v-if="scope.row.provide === '0'" type="warning" style="border: 0;background-color: transparent;">未发放</el-tag>
                    <el-tag v-if="scope.row.provide === '1'" type="success" style="border: 0;background-color: transparent;">已发放</el-tag>
                    <el-tag v-if="scope.row.provide === '2'" type="danger" style="border: 0;background-color: transparent;">已撤销</el-tag>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
                width="200">
                <template slot-scope="scope">
                    <el-button type="text" v-if="scope.row.provide === '0'" @click="toPass(scope.row.id)">通过</el-button>
                    <el-button type="text" v-if="scope.row.provide === '1'" @click="toRepeal(scope.row.id)">撤销</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            class="space-table-data"
            background
            layout="total, prev, pager, next"
            :page-size="pageSize"
            hide-on-single-page
            @current-change="loadData"
            :current-page="currentPage"
            :total="total">
        </el-pagination>
    </div>
</template>

<script>
import {getAwardGameList, getDrawRecord, updatePass, updateRepeal} from "@/api/request";

export default {
    name: "drawRecord",
    data(){
        return {
            queryField: {
                userId: '',
                awardId: '',
                gameId: '',
                provide: '',
                startTime: '',
                endTime: '',
            },
            dateValue: [],
            tableData: [],
            height: window.innerHeight - 210,
            currentPage: 1,
            pageSize: 15,
            total: 0,
            gameOptions: [],
        }
    },
    mounted() {
        this.loadData(1);
        this.loadGame();
    },
    methods: {
        loadData(index) {
            let params = {
                pageNo: index,
                pageSize: this.pageSize,
                params: this.queryField
            }
            getDrawRecord(params).then(res => {
                this.tableData = res.data.list;
                this.total = res.data.total;
                this.currentPage = index;
            })
        },
        toPass(id) {
            this.$confirm(`确定通过该奖品发放吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                updatePass(id).then(res => {
                    if (res.data.success) {
                        this.$message.success(`发放成功`);
                        this.loadData(this.currentPage);
                    } else {
                        this.$message.error(res.data.error.message);
                    }
                })
            });
        },
        toRepeal(id) {
            this.$confirm(`确定撤销该奖品发放吗？`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                updateRepeal(id).then(res => {
                    if (res.data.success) {
                        this.$message.success(`撤销成功`);
                        this.loadData(this.currentPage);
                    } else {
                        this.$message.error(res.data.error.message);
                    }
                })
            });
        },
        loadGame(){
            getAwardGameList().then(res => {
                this.gameOptions.push({game_id: '', game_name: "全部"})
                this.gameOptions = [...this.gameOptions,...res.data.data];
            })
        },
        onDataConfirm() {
            if(this.dateValue !== null){
                this.queryField.startTime = this.dateValue[0];
                this.queryField.endTime = this.dateValue[1];
            }else {
                this.queryField.startTime = "";
                this.queryField.endTime = "";
            }
        },
        reset() {
            this.queryField = {
                userId: '',
                awardId: '',
                gameId: '',
                provide: '',
                startTime: '',
                endTime: '',
            }
            this.dateValue = [];
        }
    }
}
</script>

<style scoped>

</style>