console.log('process.env',process.env)
const serverList = [
    {
        BASE_URL: 'http://192.168.68.55:8999/'
    },
    {
        BASE_URL: 'https://cpsapi.daoyigame.com/'
    },
    {
        BASE_URL: 'http://cpsapi.sw007.cn'
    }
];
let serverArr = serverList[1]
export default serverArr